<template>
  <v-autocomplete
    outlined
    v-model="selected"
    :items="channels"
    item-text="channel"
    item-value="id"
    :menu-props="{ maxHeight: '400', zIndex: 13 }"
    label="Sélectionner les channels"
    multiple
    small-chips
    deletable-chips
  ></v-autocomplete>
</template>

<script>
import debounce from "lodash/debounce";

import { computed, onMounted, watch } from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";

const COMPONENT_NAME = "ChannelFilter";

export default {
  name: COMPONENT_NAME,
  setup(props, { emit }) {
    const { store } = useProxy();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    // =>  METHODS
    const debouncedRefreshChannelList = debounce(async () => {
      await refreshChannelList();
    }, 500);

    const refreshChannelList = async () => {
      if (
        !startDate.value ||
        startDate.value.length !== 10 ||
        !endDate.value ||
        endDate.value.length !== 10
      ) {
        return;
      }

      await store.dispatch("audience/getChannelList", {
        sites: sites.value.join(","),
        categories: categories.value.join(","),
        to_exclude: excludeCategories.value,
        start_date: startDate.value,
        end_date: endDate.value,
        period_type: periodType.value,
      });
    };

    // =>  COMPUTED
    const selected = computed({
      get: () => store.getters["audience/getChannels"],
      set(e) {
        store.dispatch("audience/updateChannels", e);
      },
    });

    const categories = computed(() => store.getters["audience/getCategories"]);
    const excludeCategories = computed(
      () => store.getters["audience/getExcludeCategories"]
    );
    const sites = computed(() => store.getters["audience/getSites"]);
    const startDate = computed(() => store.getters["audience/getDates"][0]);
    const endDate = computed(() => store.getters["audience/getDates"][1]);
    const periodType = computed(() => store.getters["audience/getPeriodType"]);
    const channels = computed(() => store.getters["audience/getChannelList"]);

    // =>  WATCH
    watch(categories, async () => {
      await debouncedRefreshChannelList();
    });
    watch(excludeCategories, async () => {
      await debouncedRefreshChannelList();
    });
    watch(sites, async () => {
      await debouncedRefreshChannelList();
    });
    watch(startDate, async () => {
      await debouncedRefreshChannelList();
    });
    watch(endDate, async () => {
      await debouncedRefreshChannelList();
    });
    watch(periodType, async () => {
      await debouncedRefreshChannelList();
    });
    watch(selected, (newValue) => {
      addQueryStringParam({
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: newValue,
        is_multiple: true,
        is_integer: true,
        // is_boolean: false,
      });
    });

    // =>  HOOKS
    onMounted(async () => {
      await refreshChannelList();

      await synchronizeFilterWithQueryString({
        dispatcher: "audience/updateChannels",
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: selected.value,
        is_multiple: true,
        is_integer: true,
        // is_boolean: false,
        // dependsOn: undefined,
      });
    });

    // =>  DATA
    return {
      channels,
      selected,
    };
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 233px !important;
  right: 0 !important;
}
</style>
