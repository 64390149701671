<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="4">
        <site-group-filter store="audience" />
      </v-col>
      <v-col cols="3">
        <date-filter store="audience" periodType="past-only"></date-filter>
      </v-col>
      <v-col cols="3">
        <v-select
          outlined
          v-model="periodType"
          :items="periodTypeList"
          :menu-props="{ maxHeight: '400', zIndex: 13 }"
          label="Pour la période"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>

      <v-col cols="2" class="d-flex">
        <v-spacer />
        <apply-filters :disabled="isLoading" outlined color="secondary" />
      </v-col>

      <v-col cols="6">
        <site-filter store="audience" />
      </v-col>
      <v-col cols="6">
        <channel-filter />
      </v-col>

      <v-col cols="6">
        <category-filter store="audience" />
      </v-col>
      <v-col cols="6">
        <v-switch
          v-model="excludeCategories"
          label="A exclure"
          outlined
          dense
        ></v-switch>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiCached } from "@mdi/js";
import { computed, onMounted, watch } from "@vue/composition-api";

import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import CategoryFilter from "@/components/common/filters/CategoryFilter.vue";
import ChannelFilter from "@/components/audience/perf-per-category/filters/ChannelFilter.vue";
import DateFilter from "@/components/common/filters/DateFilter";
import SiteFilter from "@/components/common/filters/SiteFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";
import { defaultPeriodType } from "@/components/audience/perf-per-article/utils/constants";

import useAxios from "@/hooks/useAxios";
import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    CategoryFilter,
    ChannelFilter,
    DateFilter,
    SiteFilter,
    SiteGroupFilter,
    mdiCached,
  },
  setup(props, { emit }) {
    const { store } = useProxy();
    const { isLoading } = useAxios();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    const periodTypeList = [
      { id: defaultPeriodType, name: "Articles vus" },
      { id: "articles_created", name: "Articles créés" },
    ];

    // =>  METHODS
    const validatePeriodType = (periodType) => {
      const allowedValues = periodTypeList.map((entry) => entry.id);

      return allowedValues.includes(periodType)
        ? periodType
        : defaultPeriodType;
    };

    // =>  COMPUTED
    const periodType = computed({
      get() {
        const storedValue = store.getters["audience/getPeriodType"];
        const value = validatePeriodType(storedValue);
        if (value !== storedValue) {
          store.dispatch("audience/updatePeriodType", value);
        }

        return value;
      },
      set(e) {
        store.dispatch("audience/updatePeriodType", validatePeriodType(e));
      },
    });

    const excludeCategories = computed({
      get: () => store.getters["audience/getExcludeCategories"],
      set(e) {
        store.dispatch("audience/updateExcludeCategories", e);
      },
    });

    // =>  WATCH
    watch(periodType, (newValue) => {
      addQueryStringParam({
        param: URL_PARAM_NAMES["PeriodTypeFilter"],
        value: newValue,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
      });
    });

    watch(excludeCategories, (newValue) => {
      addQueryStringParam({
        param: URL_PARAM_NAMES["ToExclude"],
        value: newValue,
        // is_multiple: false,
        // is_integer: false,
        is_boolean: true,
      });
    });

    // =>  HOOKS
    onMounted(async () => {
      await synchronizeFilterWithQueryString({
        dispatcher: "audience/updatePeriodType",
        param: URL_PARAM_NAMES["PeriodTypeFilter"],
        value: periodType.value,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
        // dependsOn: undefined,
      });

      await synchronizeFilterWithQueryString({
        dispatcher: "audience/updateExcludeCategories",
        param: URL_PARAM_NAMES["ToExclude"],
        value: excludeCategories.value,
        // is_multiple: false,
        // is_integer: false,
        is_boolean: true,
        // dependsOn: undefined,
      });
    });

    // =>  DATA
    return {
      icons: {
        mdiCached,
      },
      isLoading,
      periodTypeList,
      periodType,
      excludeCategories,
    };
  },
};
</script>

<style></style>
